@import url('https://fonts.googleapis.com/css2?family=Hind:wght@500&display=swap');

html {
  font-size: 16px;
}

body {
  margin: 0;
}

.ReactModal__Body--open {
  overflow: hidden;
}

@font-face {
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 100;
  src: url('./static/fonts/proxima-nova-100.woff2') format('woff2'),
    url('./static/fonts/proxima-nova-100.woff') format('woff');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: italic;
  font-weight: 100;
  src: url('./static/fonts/proxima-nova-100-italic.woff2') format('woff2'),
    url('./static/fonts/proxima-nova-100-italic.woff') format('woff');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 300;

  src: url('./static/fonts/proxima-nova-300.woff2') format('woff2'),
    url('./static/fonts/proxima-nova-300.woff') format('woff');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: italic;
  font-weight: 300;

  src: url('./static/fonts/proxima-nova-300-italic.woff2') format('woff2'),
    url('./static/fonts/proxima-nova-300-italic.woff') format('woff');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 400;
  src: url('./static/fonts/proxima-nova-400.woff2') format('woff2'),
    url('./static/fonts/proxima-nova-400.woff') format('woff');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: italic;
  font-weight: 400;
  src: url('./static/fonts/proxima-nova-400-italic.woff2') format('woff2'),
    url('./static/fonts/proxima-nova-400-italic.woff') format('woff');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 600;

  src: url('./static/fonts/proxima-nova-600.woff2') format('woff2'),
    url('./static/fonts/proxima-nova-600.woff') format('woff');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: italic;
  font-weight: 600;

  src: url('./static/fonts/proxima-nova-600-italic.woff2') format('woff2'),
    url('./static/fonts/proxima-nova-600-italic.woff') format('woff');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 700;

  src: url('./static/fonts/proxima-nova-700.woff2') format('woff2'),
    url('./static/fonts/proxima-nova-700.woff') format('woff');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: italic;
  font-weight: 700;

  src: url('./static/fonts/proxima-nova-700-italic.woff2') format('woff2'),
    url('./static/fonts/proxima-nova-700-italic.woff') format('woff');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 800;

  src: url('static/fonts/proxima-nova-800.woff2') format('woff2');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: italic;
  font-weight: 800;

  src: url('static/fonts/proxima-nova-800-italic.woff2') format('woff2');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 900;

  src: url('./static/fonts/proxima-nova-900.woff2') format('woff2'),
    url('./static/fonts/proxima-nova-900.woff') format('woff');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: italic;
  font-weight: 900;

  src: url('./static/fonts/proxima-nova-900-italic.woff2') format('woff2'),
    url('./static/fonts/proxima-nova-900-italic.woff') format('woff');
}
