@import url(https://fonts.googleapis.com/css2?family=Hind:wght@500&display=swap);
html {
  font-size: 16px;
}

body {
  margin: 0;
}

.ReactModal__Body--open {
  overflow: hidden;
}

@font-face {
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 100;
  src: url(https://cadenceengine.salesloft.com/static/media/proxima-nova-100.8583e855.woff2) format('woff2'),
    url(https://cadenceengine.salesloft.com/static/media/proxima-nova-100.8066c00a.woff) format('woff');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: italic;
  font-weight: 100;
  src: url(https://cadenceengine.salesloft.com/static/media/proxima-nova-100-italic.aac32e4e.woff2) format('woff2'),
    url(https://cadenceengine.salesloft.com/static/media/proxima-nova-100-italic.362f73d8.woff) format('woff');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 300;

  src: url(https://cadenceengine.salesloft.com/static/media/proxima-nova-300.fd056198.woff2) format('woff2'),
    url(https://cadenceengine.salesloft.com/static/media/proxima-nova-300.fb2bc83a.woff) format('woff');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: italic;
  font-weight: 300;

  src: url(https://cadenceengine.salesloft.com/static/media/proxima-nova-300-italic.1eb147e2.woff2) format('woff2'),
    url(https://cadenceengine.salesloft.com/static/media/proxima-nova-300-italic.4f0ae16a.woff) format('woff');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 400;
  src: url(https://cadenceengine.salesloft.com/static/media/proxima-nova-400.22161a0b.woff2) format('woff2'),
    url(https://cadenceengine.salesloft.com/static/media/proxima-nova-400.483dff64.woff) format('woff');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: italic;
  font-weight: 400;
  src: url(https://cadenceengine.salesloft.com/static/media/proxima-nova-400-italic.c8522de5.woff2) format('woff2'),
    url(https://cadenceengine.salesloft.com/static/media/proxima-nova-400-italic.076f7ee0.woff) format('woff');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 600;

  src: url(https://cadenceengine.salesloft.com/static/media/proxima-nova-600.3b2fdd39.woff2) format('woff2'),
    url(https://cadenceengine.salesloft.com/static/media/proxima-nova-600.0953940f.woff) format('woff');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: italic;
  font-weight: 600;

  src: url(https://cadenceengine.salesloft.com/static/media/proxima-nova-600-italic.0fa1c021.woff2) format('woff2'),
    url(https://cadenceengine.salesloft.com/static/media/proxima-nova-600-italic.16df0967.woff) format('woff');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 700;

  src: url(https://cadenceengine.salesloft.com/static/media/proxima-nova-700.892807e4.woff2) format('woff2'),
    url(https://cadenceengine.salesloft.com/static/media/proxima-nova-700.41d8574e.woff) format('woff');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: italic;
  font-weight: 700;

  src: url(https://cadenceengine.salesloft.com/static/media/proxima-nova-700-italic.8275717b.woff2) format('woff2'),
    url(https://cadenceengine.salesloft.com/static/media/proxima-nova-700-italic.5106fb0a.woff) format('woff');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 800;

  src: url(https://cadenceengine.salesloft.com/static/media/proxima-nova-800.a06c2757.woff2) format('woff2');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: italic;
  font-weight: 800;

  src: url(https://cadenceengine.salesloft.com/static/media/proxima-nova-800-italic.a684cef4.woff2) format('woff2');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 900;

  src: url(https://cadenceengine.salesloft.com/static/media/proxima-nova-900.35d41abb.woff2) format('woff2'),
    url(https://cadenceengine.salesloft.com/static/media/proxima-nova-900.6009e0ff.woff) format('woff');
}

@font-face {
  font-family: 'proxima-nova';
  font-style: italic;
  font-weight: 900;

  src: url(https://cadenceengine.salesloft.com/static/media/proxima-nova-900-italic.40cea5be.woff2) format('woff2'),
    url(https://cadenceengine.salesloft.com/static/media/proxima-nova-900-italic.eaf18fec.woff) format('woff');
}

